import { Inject } from '@cds/common';
import { HttpService } from '@/services/common/http.service';
import { Notification } from 'element-ui';

export class WechatImageTextService {
  @Inject(HttpService) private http!: HttpService;

  //裁剪图片上传
  public uploadImages(param: Dict<any>): Promise<any> {
    let config: any = {
      headers: { 'Content-Type': 'multipart/form-data' }
    };
    let queryString: any = {
      action:'uploadimage',
      encode:'utf-8',
      agentId:sessionStorage.getItem('agentId')
    };
    return this.http.post(
      '/api/application/ueditor/cropPicUp',
      param,
      queryString,
      config
    );
  }
  //获取base64图片
  public encodeImg(param: Dict<any>): Promise<any> {
    return this.http.get('/api/application/helper/encodeImg',param);
  }

  //获取专栏
  public applicationsList(): Promise<any> {
    return this.http.get('/api/admin/applications/list');
  }

  //添加图文分类
  public articleTypeAdd(param: Dict<any>): Promise<any> {
    return this.http.post('/api/application/articleType/add', param);
  }

 //删除图文分类
  public articleTypeDelete(id: string): Promise<any> {
    return this.http.post(`/api/application/articleType/delete/${id}`);
  }

  //修改图文分类
  public articleTypeUpdate(param: Dict<any>): Promise<any> {
    return this.http.post('/api/application/articleType/update', param);
  }

  //查询图文分类
  public articleTypeQuery(param: Dict<any>): Promise<any> {
    return this.http.get('/api/application/articleType/query', param);
  }

  // 获取指定类型的图文
  public getArticleType(typeId: Dict<any>,param: Dict<any>): Promise<any> {
      let config: any = {
        headers: { 'Content-Type': 'application/json' }
      };
      let queryString: any = {};
      return this.http.get(
        `/api/application/articleType/articles/${typeId}`,
        param,
        config
      );
  }

  //获取图文
  public articleQuery(param: Dict<any>): Promise<any> {
    return this.http.post('/api/application/article/query', param);
  }
  //查询指定图文
  public articleGet(id: string): Promise<any> {
    return this.http.get(`/api/application/article/get/${id}`);
  }

  //删除图文
  public articleDelete(param: Dict<any>): Promise<any> {
    return this.http.delete('/api/application/article/delete',param);
  }

  //批量修改图文
  public articleUpdate(param: Dict<any>): Promise<any> {
    return this.http.post('/api/application/article/updates', param);
  }
  //图文分类排序
  public updatesArticleNum(param: Dict<any>): Promise<any> {
    return this.http.post('/api/application/article/updatesArticleNum', param);
  }

  //单个图文修改
  public articleUpdateOne(param: Dict<any>): Promise<any> {
    return this.http.post('/api/application/article/update', param);
  }
  //添加图文
  public articleAdd(articleIds:Dict<any>,param: Dict<any>): Promise<any> {
    return this.http.post(`/api/application/article/adds/${articleIds}`, param);
  }
  // 根据id获取图文详情 不包括子图文
  public articleDetail(id: string): Promise<any> {
    return this.http.get(`/api/application/article/info/${id}`);
  }

  //获取id字符串
  public articleToid(param: Dict<any>): Promise<any> {
    return this.http.get('/api/application/article/toid',param);
  }

}
