import { Inject } from '@cds/common';
import { HttpService } from '@/services/common/http.service';

export class materialApi {
  [key: string]: any;

  public value: any;

  @Inject(HttpService) private http!: HttpService;

  // 20220720
  // 素材管理接口集锦
  // 通过type获取各管理页面list  1 图片 2 视频 3 语音  4 文件
  public getList(param: Dict<any>): Promise<any> {
    return this.http.post('/api/application/media/list', param);
  }
  public delePic(param: Dict<any>): Promise<any> {
    return this.http.get(`/api/application/media/delete/${param.id}`);
  }
  // 图片上传
  public savePic(param: Dict<any>): Promise<any> {
    return this.http.post('/api/application/media/save', param);
  }
  // 外部链接上传 /api/application/media/upload
  public uploadUrlPic(param: Dict<any>): Promise<any> {
    return this.http.post('/api/application/media/urls-upload', param);
  }
  // 本地文件上传 /api/application/media/upload
  public uploadUserPic(param: Dict<any>): Promise<any> {
    return this.http.post('/api/application/media/upload', param);
  }
}
